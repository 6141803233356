import { Container } from '@/components/Container'
import classNames from 'classnames'
import { PropsWithChildren } from 'react'

export const GlobalLayout = ({ children }: PropsWithChildren<{}>) => {
  return (
    <>
      <Container>
        <div className={classNames(['flex', 'items-center', 'justify-center', 'h-14'])}>
          <div>
            <img
              className={classNames(['h-7'])}
              src="/logo.png"
              alt="logo"
            />
          </div>
        </div>
      </Container>

      {children}
    </>
  )
}
