import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://feef6872e1364f398715145dd7fce1f2@o4503932173418496.ingest.sentry.io/4504484342923264',
  tracesSampleRate: 1.0,
})
