import classNames from 'classnames'

interface IProps {
  children: React.ReactElement
  isFullWidth?: boolean
}
export const Container = ({ children, isFullWidth }: IProps) => {
  return (
    <div
      className={classNames([
        'max-w-[500px]  relative w-full ml-auto mr-auto',
        !isFullWidth && 'px-[1.5rem]',
      ])}
    >
      {children}
    </div>
  )
}
