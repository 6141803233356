import '@/styles/global.css'

import 'sentry.client.config'
import 'sentry.server.config'
import 'sentry.edge.config'
import { AppPropsWithLayout } from '@/@types/next'
import { GlobalLayout } from '@/layouts/GlobalLayout'
import { createTheme, NextUIProvider } from '@nextui-org/react'
import { Toaster } from 'react-hot-toast'
import { QueryClient, QueryClientProvider } from 'react-query'
import '../utils/loadAmplitude'
import NextNProgress from 'nextjs-progressbar'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'

declare global {
  interface Window {
    fbq: any
  }
}

const theme = createTheme({
  type: 'light', // it could be "light" or "dark"
  theme: {
    colors: {
      primary: '#000000',
      secondary: '#F9CB80',
      error: '#FCC5D8',
    },
  },
})

export const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  const getLayout =
    Component.getLayout ?? ((page: React.ReactNode) => <GlobalLayout>{page} </GlobalLayout>)
  const queryClient = new QueryClient()

  console.log('client_id', process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID)
  return (
    <PayPalScriptProvider
      key={'paypal'}
      options={{
        'client-id': process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID ?? '',
        currency: 'USD',
      }}
    >
      <QueryClientProvider client={queryClient}>
        <NextUIProvider theme={theme}>
          <NextNProgress
            color={'#131313'}
            options={{ showSpinner: false }}
          />
          {getLayout(<Component {...pageProps} />)}
          <Toaster />
        </NextUIProvider>
      </QueryClientProvider>
    </PayPalScriptProvider>
  )
}

export default App
